.input-group-login{
    display: inline-block !important;
}
.company-search-btn{
    position: absolute;
    right: 0;
}
.company-search-btn button{
    padding: 13px !important;
}
label{
    margin-top: 10px;
}
.p-invalid{
    border-color: #e24c4c ;
}
.specdiv input#inlineRadio1, .specdiv input#inlineRadio2 {
    vertical-align: middle !important;  
    margin-right: 5px;
    width: 20px;
    height: 20px;
}
span#basic {
    width: 100%;
}
.additional .p-hidden-accessible{
    position: unset !important;
}
 .carousel-demo .product-item .product-item-content {
    border: 1px solid var(--surface-d);
    border-radius: 3px;
    margin: .3rem;
    text-align: center;
    padding: 2rem 0;
}

.carousel-demo .product-item .product-image {
    width: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
} 
.editor-description span.ql-font.ql-picker, .editor-description button.ql-link,.editor-description button.ql-image, .editor-description button.ql-code-block, .editor-description button.ql-clean {
    display: none !important;
}
.inputswitch-css{
    vertical-align: middle !important;
}
.reports-charts .icon {
    /* display: flex; */
    /* align-items: center;
    justify-content: center; */
    width: 33px;
    height: 33px;
    border-radius: 4px;
    background-color: #001F4D !important;
}
.reports-charts i {
    color: #fff;
}
.p-datatable.p-component.p-datatable-responsive-scroll.demo  tbody > tr:last-child{
  font-weight: bolder;
}
.disabled-white-color {
    background-color: #fff !important;
}
label.input.input-file {
    position: relative;
    display: block;
    font-weight: 400;
}

.input-file .button {
    position: absolute;
    top: 4px;
    right: 4px;
    float: none;
    height: 28px;
    margin: 0;
    padding: 3px 25px;
    font-size: 13px;
    line-height: 22px;
    background-color: #6F2D80;
    opacity: .8;
    outline: 0;
    border: 0;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;

}

.input-file .button input {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    font-size: 30px;
    cursor: pointer;
    opacity: 0;
}


.input input {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    height: 35px;
    padding: 8px 10px;
    outline: 0;
    border-width: 1px;
    border-style: solid;
    background: #fff !important;
    font: 13px/16px 'Open Sans', Helvetica, Arial, sans-serif;
    color: #404040;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.doc-upload span.p-button.p-component.p-fileupload-choose{
    width: 100% !important;
}
 .p-column-filter-overlay .p-column-filter-add-rule, .p-column-filter-overlay-menu .p-column-filter-operator{
    display: none !important;
}
.p-button.p-button-outlined{
    color: #fff !important;
}
/* ******************Add label for multiselect dropdown************************* */

.p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box:after{
    content: "Select All";
    left: 27px;
    position: absolute;
    width: 100px;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{
    color: #000 !important;
}
.p-button.p-button-text{
    color: #fff !important;
}
@media (min-width:320px) and (max-width: 992px){
    .servicemain{
        /* text-align: right; */
    }
}
@media (min-width:320px) and (max-width: 426px){
    .login-box{
width: 90% !important;
    }
    .editbtn{
      margin-bottom: 5px;  
    }  
    button.p-paginator-next.p-paginator-element.p-link, .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last, .p-paginator .p-paginator-pages .p-paginator-page{
        min-width: unset !important;
    }
    .p-paginator.p-component.p-paginator-bottom, span.p-paginator-pages, .p-paginator-page {
        font-size: 14px;
    }
}
@media (min-width:320px) and (max-width: 767px){
   
    .p-panel-header.p-jc-start{
        margin: 10px; 
    }
    .servicemain{
       text-align: right;
       margin-top: 10px;
    }
    .test div{
        width: unset !important;
        height: unset !important;
    }
}
@media (min-width:320px) and (max-width: 769px){
  
}
@media (min-width:768px) and (max-width: 768px){
    .videodiv button.p-button.p-component{
        width: 100px !important;
        font-size: 13px;
    }
    .videodiv .pi.pi-check
    {
         font-size: 13px !important;
     }
     .test div{
        width: unset !important;
    }
     .editbtn{
         width: 30px !important;
         font-size: 12px;
         padding: 10px 0 !important;
        
     }
}
@media (min-width:320px) and (max-width: 320px){
    .videodiv button.p-button.p-component{
        width: 100px !important;
        font-size: 12px;
    }
    .videodiv .pi.pi-check
   {
        font-size: 12px !important;
    }
    .servicemain button, .servicemain .pi{
        font-size: 11px !important;
    }
    .p-paginator.p-component.p-paginator-bottom, span.p-paginator-pages, .p-paginator-page {
        font-size: 15px !important;
    }
    button.p-paginator-next.p-paginator-element.p-link, .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last, .p-paginator .p-paginator-pages .p-paginator-page{
        min-width: 15px !important;
    }
}
.validation-error-msg {
    color: red;
}

.p-button{
    background-color: #6f2d80 !important;
    border-color: #6f2d80 !important;
}
.p-autocomplete{
    width: 100% !important;
}

.p-autocomplete-input{
    width: 100% !important;
}

.p-autocomplete1{
    width: 100% !important;
}

.p-autocomplete1-input{
    width: 100% !important;
}

.multiselectnew .p-multiselect-trigger {
    background-color: #6f2d80 !important;
    border-color: #6f2d80 !important;
}

.multiselectnew .p-multiselect-trigger .p-multiselect-trigger-icon{
color:#fff ;
}

.panelHeight .p-panel .p-panel-content{
min-height: 710px;
}
.rtgTextArea{
    resize: inherit;
    height: 100px;
}
.userdetails-search{
    padding: 7px 20px !important;
}